import React from 'react';
import { graphql } from 'gatsby';
import { useCMSState } from '@internal/useCMSHook';
import { useCMSCollectionState } from '@internal/useCMSCollectionHook';
import memoize from 'memoize-one';
import { Helmet } from 'react-helmet';
import { MRKPage } from '../../../common/fragments/MRKPage';
import { getObjectVersionOfMRKTemplate } from '../../../common/helpers/mrkContentOnly';
import { CaseStudy } from '../../../common/fragments/CaseStudy';
import {
  CaseStudyFilter,
  CaseStudiesFilters,
  useCaseStudiesFilters,
  isFilterKey,
} from '../CaseStudiesFilters';
import { CaseStudiesList } from '../CaseStudiesList';
import { GatsbyTemplateProps } from '../../../common/interfaces/gatsby-template-props.interface';
import Layout from '../../layout/layout';
import '../../../styles/modules/pages/case-studies.scss';
import { HeroBlade } from '../../common/refresh-blades/HeroBlade';
import { Container, Div } from '../../common/Elements';
import { FeaturesBlade } from '../../common/refresh-blades/multipleFeaturesBlade/FeaturesBlade';
import { ContentfulBladeQuote } from '../../../contentful/content-types/blades/blade-quote';
import { QuoteBlade } from '../../common/refresh-blades/QuoteBlade';
import { EnablementBlade } from '../../common/refresh-blades/EnablementBlade';
import { Divider } from '../../common/Divider';
import { ContentfulBladeHero } from '../../../contentful/content-types/blades/blade-hero-animation';
import { ContentfulBladeEnablement } from '../../../contentful/content-types/blades/blade-enablement';
import { ContentfulBladeThreeFeatures } from '../../../contentful/content-types/blades/blade-three-features';
import { useConfiguration } from '../../../common/hooks/useConfiguration';
import { MainFooter } from '../../layout/refreshed-footer/footer.interfaces';
import { ContentfulNavNavigationGroup } from '../../../contentful/content-types/nav-navigation-group';

interface CaseStudyQueryResult {
  page: MRKPage;
  englishPage: MRKPage;
  caseStudies: {
    totalCount: number;
    nodes: CaseStudy[];
  };
  headerData: ContentfulNavNavigationGroup;
  footerData: MainFooter;
  hero: ContentfulBladeHero;
  features: ContentfulBladeThreeFeatures;
  quote: ContentfulBladeQuote;
  enablement: ContentfulBladeEnablement;
}

export interface CaseStudyTemplateProps extends GatsbyTemplateProps<any> {
  data: CaseStudyQueryResult; // passed from gatsby results from graphql pageQuery
}

export const pageQuery = graphql`
  query($locale: String!) {
    global: mrkConfigStaticStrings(
      contentfulId: { eq: "1uP0ac1EPF1qsviUi4I9Vz" }
      locale: { eq: $locale }
    ) {
      ...GlobalStringsFragment
    }

    page: mrkPage(url_pageUrl: { eq: "case-studies" }, locale: { eq: $locale }) {
      ...MRKPageFragment
    }

    hero: bladeHeroAnimation(contentfulId: { eq: "5JYk5jCbjWvNwzVGDL9wzT" }) {
      ...bladeHeroAnimationFragment
    }

    quote: bladeQuote(contentfulId: { eq: "1E0xHZNjhW0Gwvqstoe1rD" }) {
      ...bladeQuoteFragment
    }
    enablement: bladeEnablement(contentfulId: { eq: "5c1I3mIeKF7ROMpxv8nKpy" }) {
      ...bladeEnablementFragment
    }

    features: bladeFourFeatures(contentfulId: { eq: "2jznD55p3paVdAZWeXI5Qw" }) {
      ...bladeFourFeaturesFragment
    }

    caseStudies: allCaseStudy(
      filter: { locale: { eq: $locale }, hideFromCaseStudiesPage: { ne: true } }
    ) {
      nodes {
        ...CaseStudyFragment
      }
    }

    # Need to also fetch english page for proper filtering
    englishPage: mrkPage(url_pageUrl: { eq: "case-studies" }, locale: { eq: "en-US" }) {
      ...MRKPageFragment
    }
    headerData: navNavigationGroup(
      contentfulId: { eq: "3hzSjT58plRWzuQrC4j4ny" }
      locale: { eq: $locale }
    ) {
      ...navNavigationGroupFragment
    }
    footerData: mainFooter(locale: { eq: $locale }) {
      ...mainFooterFragment
    }
  }
`;

function getVideoCaseStudies(caseStudies: CaseStudy[]) {
  return caseStudies.filter(caseStudy => caseStudy.cloudflareStreamVideoId);
}

function getNonVideoCaseStudies(caseStudies: CaseStudy[]) {
  return caseStudies.filter(caseStudy => !caseStudy.cloudflareStreamVideoId);
}

const truncatedLengthOfCaseStudies = 12;

function getInterleavedCaseStudies(
  videoCaseStudies: CaseStudy[],
  nonVideoCaseStudies: CaseStudy[]
) {
  let result = [];
  let videoList = videoCaseStudies.sort(caseStudiesSort);
  let nonVideoList = nonVideoCaseStudies.sort(caseStudiesSort);
  const totalLength = videoList.length + nonVideoList.length;

  for (let i = 0; i < totalLength; i++) {
    if ((i === 0 || i % truncatedLengthOfCaseStudies === 0) && videoList.length > 0) {
      const caseStudy = videoList.shift();
      if (caseStudy) {
        result.push(caseStudy);
      }
    } else if (nonVideoList.length > 0) {
      const caseStudy = nonVideoList.shift();
      if (caseStudy) {
        result.push(caseStudy);
      }
    }
  }

  return result;
}

const caseStudyKeyFilterMap: {
  [K in Exclude<keyof CaseStudyFilter, 'showing'>]: keyof CaseStudy;
} = {
  industry: 'industryNew52420',
  region: 'regionNew52520',
  usecase: 'primaryUseCases',
  product: 'product',
};

function getFilteredCaseStudies(caseStudies: CaseStudy[], filters: CaseStudyFilter) {
  return caseStudies
    .filter(caseStudy => {
      return Object.keys(caseStudyKeyFilterMap).every(key => {
        if (!isFilterKey(key)) return true;

        const filter = filters[key];

        if (filter === null) return true;

        const caseStudyKey = caseStudyKeyFilterMap[key];
        const value = caseStudy[caseStudyKey];

        if (Array.isArray(value)) {
          return value.includes(filter);
        }

        return false;
      });
    })
    .filter((_, index) => filters.showing === 'all' || index <= truncatedLengthOfCaseStudies);
}

const getObjectVersionOfMRKTemplateMemoized = memoize(getObjectVersionOfMRKTemplate);

function caseStudiesSort(a: Pick<CaseStudy, 'orderDate'>, b: Pick<CaseStudy, 'orderDate'>) {
  // Sort dates in descending order
  return new Date(b.orderDate).valueOf() - new Date(a.orderDate).valueOf();
}

const CaseStudiesTemplate: React.FC<CaseStudyTemplateProps> = ({ data, pageContext }) => {
  const config = useConfiguration();
  let {
    page,
    englishPage,
    caseStudies: allCaseStudies,
    enablement,
    features,
    footerData,
    hero,
    headerData,
    quote,
  } = data;
  if (config.targetEnv === 'staging') {
    page = useCMSState<MRKPage>(page);
    englishPage = useCMSState<MRKPage>(englishPage);
    enablement = useCMSState<ContentfulBladeEnablement>(enablement);
    features = useCMSState<ContentfulBladeThreeFeatures>(features);
    footerData = useCMSState<MainFooter>(footerData);
    hero = useCMSState<ContentfulBladeHero>(hero);
    quote = useCMSState<ContentfulBladeQuote>(quote);
    headerData = useCMSState<ContentfulNavNavigationGroup>(headerData);
    allCaseStudies = useCMSCollectionState<{
      totalCount: number;
      nodes: CaseStudy[];
    }>('caseStudy', allCaseStudies);
  }

  const { nodes: caseStudies } = allCaseStudies;

  const [filters, setFilters] = useCaseStudiesFilters();
  const mkdContent = getObjectVersionOfMRKTemplateMemoized(page.ref_template.ref_content);
  const englishMkdContent = getObjectVersionOfMRKTemplateMemoized(
    englishPage.ref_template.ref_content
  );
  const videoCaseStudies = getVideoCaseStudies(caseStudies);
  const nonVideoCaseStudies = getNonVideoCaseStudies(caseStudies);
  const interleavedCaseStudies = getInterleavedCaseStudies(videoCaseStudies, nonVideoCaseStudies);
  const filteredCaseStudies = getFilteredCaseStudies(interleavedCaseStudies, filters);

  return (
    <Layout headerData={headerData} pageContext={pageContext} footerData={footerData}>
      <Helmet>
        <title>{page.str_metadataDescription} | Cloudflare</title>
        <meta name="title" content={page.str_pageTitle} />
        <meta name="description" content={page.str_metadataDescription} />
      </Helmet>

      <Div>
        <HeroBlade
          blade={hero}
          page={{
            contentTypeId: 'page',
            contentfulId: '',
            relativePath: '',
            pageName: '',
            metaTitle: '',
            metaDescription: '',
          }}
        />

        <FeaturesBlade blade={features} />
        <Container>
          <Divider paddingVertical={0} />
        </Container>
        <Container paddingTop={[4, 4, 4, 9]} paddingBottom={[7, 7, 7, 10]}>
          <CaseStudiesFilters
            englishContent={englishMkdContent}
            content={mkdContent}
            filters={filters}
            setFilters={setFilters}
          />
          <CaseStudiesList
            caseStudies={filteredCaseStudies}
            content={mkdContent}
            isTruncated={
              filters.showing !== 'all' &&
              filteredCaseStudies.length >= truncatedLengthOfCaseStudies
            }
            filtersApplied={Object.keys(filters).some(key => (filters as any)[key] !== null)}
            loadMore={() => setFilters({ ...filters, showing: 'all' })}
          />
        </Container>

        <QuoteBlade blade={quote} />

        <EnablementBlade
          page={{
            contentTypeId: 'page',
            contentfulId: '',
            relativePath: '',
            pageName: '',
            metaTitle: '',
            metaDescription: '',
          }}
          blade={enablement}
        />
      </Div>
    </Layout>
  );
};

export default CaseStudiesTemplate;
